<template>
  <v-container class="pa-0">
    <v-row class="justify-space-between">
      <v-col cols="auto" class="px-2">
        <p class="overline-2 grey-regular mb-0 text-center">
          {{ t('Msg_.hourlyWage') }}
        </p>
        <p
          class="body-text-4 px-0 text-center mb-0"
          data-testid="tour-details-hourly-wage-field"
        >
          {{
            '€ ' +
            helpers.convertValueToCurrency(
              toursStore.tourDetails.hourlyCostComponents.hourlyWage
            )
          }}
        </p>
      </v-col>
      <v-col cols="auto" class="px-2">
        <p class="overline-2 grey-regular mb-0 text-center">
          {{ t('Msg_.additionals') }}
        </p>
        <p
          class="body-text-4 px-0 text-center mb-0"
          data-testid="tour-details-additionals-field"
        >
          {{
            '€ ' +
            helpers.convertValueToCurrency(
              toursStore.tourDetails.hourlyCostComponents.additionals
            )
          }}
        </p>
      </v-col>
      <v-col cols="auto" class="px-2">
        <p class="overline-2 grey-regular mb-0 text-center">
          {{ t('Msg_.salaryTax') }}
        </p>
        <p
          class="body-text-4 px-0 text-center mb-0"
          data-testid="tour-details-salary-tax-field"
        >
          {{
            '€ ' +
            helpers.convertValueToCurrency(
              toursStore.tourDetails.hourlyCostComponents.salaryTax
            )
          }}
        </p>
      </v-col>
      <v-col cols="auto" class="px-2">
        <p class="overline-2 grey-regular mb-0 text-center">
          {{ t('Msg_.timeInCare') }}
        </p>
        <p
          class="body-text-4 px-0 text-center mb-0"
          data-testid="tour-details-time-in-care-field"
        >
          <RegularBadge
            :text="
              '€ ' +
              helpers.convertValueToCurrency(
                toursStore.tourDetails.hourlyCostComponents.timeInCare
              )
            "
            text-color=""
            color="ashLight"
            class="body-text-3 text-center mb-0"
          />
        </p>
      </v-col>
      <v-col cols="auto" class="px-2">
        <p class="overline-2 grey-regular mb-0 text-center">
          {{ t('Msg_.overheadComponent') }}
        </p>
        <p
          class="body-text-4 px-0 text-center mb-0"
          data-testid="tour-details-oh-component-field"
        >
          {{
            '€ ' +
            helpers.convertValueToCurrency(
              toursStore.tourDetails.hourlyCostComponents.overHeadComponent
            )
          }}
        </p>
      </v-col>
      <v-col cols="auto" class="px-2">
        <p class="overline-2 grey-regular mb-0 text-center">
          {{ t('Msg_.futureChanges') }}
        </p>
        <p
          class="body-text-4 px-0 text-center mb-0"
          data-testid="tour-details-future-changes-field"
        >
          {{
            '€ ' +
            helpers.convertValueToCurrency(
              toursStore.tourDetails.hourlyCostComponents.futureChanges
            )
          }}
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { useI18n } from 'vue-i18n-bridge';
import { useToursStore } from '@/store/pinia/tours.store.js';
import helpers from '@/helpers/helpers';
import languages from '../locales/languages';
import RegularBadge from '@/components/shared/customComponents/alertsBadges/regular-badge.vue';

const toursStore = useToursStore();
const { t } = useI18n({ locale: 'en', messages: languages });
</script>

<style lang="scss" scoped>
.grey-regular {
  color: $grey-regular !important;
}
</style>
