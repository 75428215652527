<template data-testid="tours-profitability-settings-pannel">
  <v-card class="rounded-lg shadow-regular pa-6">
    <v-card-title class="pa-0 heading-h4">
      {{ t('Title_.subTitle.profitabilitySettings') }}
    </v-card-title>
    <v-card-text class="pa-0">
      <v-container>
        <v-row>
          <v-col
            cols="4"
            class="d-flex pl-0 align-end"
            data-testid="tours-profitability-settings-pannel-overhead-costs"
          >
            <RegularTextInput
              :label="t('Msg_.overheadCosts')"
              class="width-100"
              prefix="€"
              v-model="toursStore.profitabilitySettings.overheadCost"
              :disabled="!canUser(['Profitability.Settings.Update'])"
              @change="
                updateOverheadCost(helpers.convertValueToCurrency($event))
              "
              @focus="
                updateOverheadCost(helpers.convertCurrencyToFloat($event))
              "
              :rules="[rules.currency(t('Msg_.rules.onlyNumbersAndDecimals'))]"
            />
          </v-col>
          <v-col
            cols="4"
            class="d-flex align-end"
            data-testid="tours-profitability-settings-pannel-total-nurses"
          >
            <RegularTextInput
              :label="t('Msg_.totalNumberNursesInHub')"
              class="width-100"
              v-model="toursStore.profitabilitySettings.totalNumberOfNurses"
              disabled
            />
          </v-col>
          <v-col
            cols="4"
            class="d-flex align-end pr-0"
            data-testid="tours-profitability-settings-pannel-total-hours"
          >
            <RegularTextInput
              :label="t('Msg_.totalNumberWorkingHours')"
              v-model="
                toursStore.profitabilitySettings.totalNumberOfWorkingHours
              "
              class="width-100"
              disabled
            />
          </v-col>
        </v-row>
        <v-row v-if="canUser(['Profitability.Settings.Update'])">
          <v-col class="pa-0">
            <v-btn
              class="main-action-variant-btn"
              @click="resetOverheadCost()"
              data-testid="tours-profitability-settings-reset-btn"
            >
              {{ t('Action_.buttons.reset') }}
            </v-btn>
            <v-btn
              class="main-action-btn ml-4"
              @click="submitOverheadCost()"
              data-testid="tours-profitability-settings-update-btn"
            >
              {{ t('Action_.buttons.update') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script setup>
import { useI18n } from 'vue-i18n-bridge';
import { usePermissions } from '@/helpers/composables/permissions.js';
import { useToursStore } from '../../../store/pinia/tours.store';
import helpers from '@/helpers/helpers';
import languages from '../locales/languages';
import RegularTextInput from '@/components/shared/customComponents/inputs/regular-text-input.vue';
import rules from '@/helpers/rules';

const { t } = useI18n({ locale: 'en', messages: languages });
const { canUser } = usePermissions();
const toursStore = useToursStore();

// Methods
const updateOverheadCost = (newValue) => {
  toursStore.profitabilitySettings.overheadCost = newValue;
};

const submitOverheadCost = async () => {
  await toursStore.submitOverheadCost();
};

const resetOverheadCost = () => {
  toursStore.profitabilitySettings.overheadCost =
    helpers.convertValueToCurrency(toursStore.overheadCostReset);
};
</script>

<style scoped lang="scss">
.width-100 {
  width: 100%;
}
</style>
