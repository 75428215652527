<template data-testid="tour-task-card">
  <v-card class="rounded-lg border-grey-small" elevation="0">
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <RegularBadge
            color="#E9F1F6"
            text-color="blueRegular"
            :text="task.startTime || '-'"
            data-testid="tour-task-card-badge"
            class="caption-2"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="d-flex justify-start align-center">
          <div class="index-border d-flex justify-center align-center">
            <span
              class="index-text badge-text-size"
              data-testid="tour-task-card-index"
            >
              {{ index + 1 }}
            </span>
          </div>
          <span
            class="pl-2 body-text-3 mineBlack--text text-truncate"
            data-testid="tour-task-card-patient-name-field"
          >
            {{ checkIfItsOfficeOrPatient }}
          </span>
        </v-col>
      </v-row>
      <v-row class="d-flex">
        <v-col cols="6">
          <p
            class="overline-2 greyRegular--text mb-1"
            data-testid="tour-task-card-duration-label"
          >
            {{ t('Msg_.duration') }}
          </p>
          <p
            class="body-text-2 mineBlack--text"
            data-testid="tour-task-card-duration-value"
          >
            {{ task.duration || '-' }}
          </p>
        </v-col>
        <v-col cols="6">
          <p
            class="overline-2 greyRegular--text mb-1"
            data-testid="tour-task-card-driving-label"
          >
            {{ t('Msg_.driving') }}
          </p>
          <p
            class="body-text-2 mineBlack--text"
            data-testid="tour-task-card-driving-value"
          >
            {{ task.drivingTime || '-' }}
          </p>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import { computed, defineProps } from 'vue';
import { useI18n } from 'vue-i18n-bridge';
import languages from '../../locales/languages';
import RegularBadge from '@/components/shared/customComponents/alertsBadges/regular-badge.vue';

const { t } = useI18n({ locale: 'en', messages: languages });

// --- Props ---
// eslint-disable-next-line no-unused-vars
const props = defineProps({
  task: {
    type: Object,
    required: true,
  },

  index: {
    type: Number,
    required: true,
  },
});

// --- Computed ---
const checkIfItsOfficeOrPatient = computed(() => {
  return props.task.taskType.Value == 1
    ? props.task.taskName
    : props.task.patientName || '';
});
</script>

<style lang="scss" scoped>
.index-border {
  width: 16px;
  height: 16px;
  border-radius: 16px;
  border: solid 1.5px #212121;
}

.index-text {
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0.4px;
  line-height: 14px;
  color: #212121;
}

.badge-text-size {
  font-size: 10px;
}
</style>
