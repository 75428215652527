<template data-testid="tours-information-card">
  <v-card
    class="pa-0 not-selectable relative-position rounded-lg shadow-regular"
  >
    <div
      v-if="tour.shiftId === toursStore.selectedTour"
      class="pa-0 ma-0 border-selected"
    ></div>
    <v-container class="pa-6" @click="emit('click', tour.shiftId)">
      <v-row class="ma-0 pb-0">
        <v-col
          class="pa-0 d-flex justify-space-between"
          :class="tourNameClass"
          data-testid="tour-information-card-name-field"
        >
          <span class="pa-0 ma-0">
            {{ tour.shiftName }}
          </span>
          <v-icon
            v-if="!isProfitabilityCalculable"
            class="pa-0 ma-0"
            color="yellowRegular"
            icon-size="24"
          >
            {{ 'mdi-alert' }}
          </v-icon>
        </v-col>
      </v-row>
      <v-row class="ma-0 pt-0 pb-3">
        <v-col
          data-testid="tour-information-card-employee-complete-name-field"
          class="pl-0 pt-1"
        >
          <span class="pa-0 body-text-1 greyDark--text">
            {{ tour.employeeNoventiJobCode }}
          </span>
          <span class="pa-0 body-text-2 greyDark--text">
            {{ tour.employeeCompleteName }}
          </span>
        </v-col>
      </v-row>

      <v-divider></v-divider>

      <v-row class="ma-0 pt-3 pb-2">
        <v-col cols="10" class="pa-0 body-text-2 greyDark--text">
          {{ t('Title_.subTitle.missingAndExpiredPrescriptions') }}
        </v-col>
        <v-col
          class="body-text-4 d-flex justify-end pa-0"
          data-testid="tour-information-card-missing-prescriptions-field"
        >
          {{ tour.missingPrescriptions }}
        </v-col>
      </v-row>
      <v-row class="ma-0 pb-3">
        <v-col cols="10" class="pa-0 body-text-2 greyDark--text">
          {{ t('Title_.subTitle.expiringPrescriptions') }}
        </v-col>
        <v-col
          class="body-text-4 d-flex justify-end pa-0"
          data-testid="tour-card-expiring-prescriptions-field"
        >
          {{ tour.expiringPrescriptions }}
        </v-col>
      </v-row>

      <v-divider></v-divider>

      <v-row class="ma-0 pt-3">
        <v-col class="pa-0">
          <div class="d-flex justify-center overline-2 grey-regular pb-2">
            {{ t('Msg_.billableTime') }}
          </div>

          <div class="d-flex justify-center">
            <RegularBadge
              :text="tour.billableTime"
              color="ashLight"
              text-color=""
              class="body-text-4 text-center mb-0"
              data-testid="tour-information-card-billable-time-field"
            />
          </div>
        </v-col>
        <v-col class="pa-0">
          <div class="d-flex justify-center overline-2 greyRegular--text pb-2">
            {{ t('Msg_.totalTime') }}
          </div>
          <div class="d-flex justify-center">
            <RegularBadge
              :text="tour.totalTime"
              color="ashLight"
              text-color=""
              class="body-text-4 text-center mb-0"
              data-testid="tour-information-card-total-time-field"
            />
          </div>
        </v-col>
        <v-col class="pa-0">
          <div class="d-flex justify-center overline-2 greyRegular--text pb-2">
            {{ t('Msg_.profit') }}
          </div>
          <div
            class="d-flex justify-center"
            data-testid="tour-information-card-profit-field"
          >
            <RegularBadge
              :text="`€ ${helpers.convertValueToCurrency(tour.profit)}`"
              :color="regularBadgeColor"
              :text-color="regularBadgeTextColor"
              class="body-text-3 text-center mb-0"
              data-testid="tour-information-card-profit-field"
            />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script setup>
import { defineProps, defineEmits, computed } from 'vue';
import { useI18n } from 'vue-i18n-bridge';
import { useToursStore } from '@/store/pinia/tours.store.js';
import helpers from '@/helpers/helpers';
import languages from '../../locales/languages.js';
import RegularBadge from '@/components/shared/customComponents/alertsBadges/regular-badge.vue';

const toursStore = useToursStore();
const emit = defineEmits(['click']);
const { t } = useI18n({ locale: 'en', messages: languages });

// --- Props ---
const props = defineProps({
  tour: {
    type: Object,
    required: true,
  },
});

// --- Computed ---
const regularBadgeColor = computed(() => {
  if (!isProfitabilityCalculable.value) {
    return 'yellowLight';
  }
  return props.tour.profit > 0 ? 'lowOpacityGreen' : 'redErrorLight';
});

const regularBadgeTextColor = computed(() => {
  if (!isProfitabilityCalculable.value) {
    return 'yellowDark';
  }
  return props.tour.profit > 0 ? 'caribbeanDark' : 'redDanger';
});

const tourNameClass = computed(() => {
  return props.tour.shiftId === toursStore.selectedTour
    ? 'subtitle-text-1'
    : 'subtitle-text-2';
});

const isProfitabilityCalculable = computed(() => {
  return !!props.tour.employeeId;
});
</script>

<style lang="scss" scoped>
.border-selected {
  position: absolute;
  background-color: $blue-regular;
  width: 8px;
  height: 100%;
  border-radius: 10px 0px 0px 10px !important;
}

.not-selectable {
  -webkit-touch-callout: none; /* iPhone OS, Safari */
  -webkit-user-select: none; /* Chrome, Safari 3 */
  -khtml-user-select: none; /* Safari 2 */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
  user-select: none; /* likely future implementation */
}

.relative-position {
  position: relative;
}
</style>
