<template>
  <v-container class="pa-0">
    <v-row class="justify-space-between">
      <v-col cols="auto">
        <p class="overline-2 greyRegular--text mb-0 text-center">
          {{ t('Msg_.billableTime') }}
        </p>

        <p
          class="body-text-4 px-0 text-center mb-0"
          data-testid="tour-summary-billable-time-field"
        >
          {{ toursStore.tourDetails.billableTime }}
        </p>
      </v-col>
      <v-col cols="auto">
        <p class="overline-2 greyRegular--text mb-0 text-center">
          {{ t('Msg_.other') }}
        </p>
        <p
          class="body-text-4 px-0 text-center mb-0"
          data-testid="tour-summary-other-time-field"
        >
          {{ toursStore.tourDetails.otherTime }}
        </p>
      </v-col>
      <v-col cols="auto">
        <p class="overline-2 greyRegular--text mb-0 text-center">
          {{ t('Msg_.driving') }}
        </p>

        <p
          class="body-text-4 px-0 text-center mb-0"
          data-testid="tour-summary-driving-time-field"
        >
          {{ toursStore.tourDetails.drivingTime }}
        </p>
      </v-col>
      <v-col cols="auto">
        <p class="overline-2 greyRegular--text mb-0 text-center">
          {{ t('Msg_.totalTime') }}
        </p>

        <p class="body-text-4 px-0 text-center mb-0">
          <RegularBadge
            data-testid="tour-summary-total-time-field"
            text-color=""
            color="ashLight"
            :text="toursStore.tourDetails.totalTime"
            class="body-text-3 text-center"
          />
        </p>
      </v-col>
      <v-col cols="auto">
        <p class="overline-2 greyRegular--text mb-0 text-center">
          {{ t('Msg_.cost') }}
        </p>

        <p
          class="body-text-4 px-0 text-center mb-0"
          data-testid="tour-summary-cost-field"
        >
          {{
            '€' + helpers.convertValueToCurrency(toursStore.tourDetails.cost)
          }}
        </p>
      </v-col>
      <v-col cols="auto">
        <p class="overline-2 greyRegular--text mb-0 text-center">
          {{ t('Msg_.revenue') }}
        </p>

        <p
          class="body-text-4 px-0 text-center mb-0"
          data-testid="tour-summary-revenue-field"
        >
          {{
            '€' + helpers.convertValueToCurrency(toursStore.tourDetails.revenue)
          }}
        </p>
      </v-col>
      <v-col cols="auto">
        <p class="overline-2 greyRegular--text mb-0 text-center mb-0">
          {{ t('Msg_.profit') }}
        </p>
        <p class="body-text-4 px-0 text-center mb-0">
          <RegularBadge
            :text-color="regularBadgeColors.textColor"
            :color="regularBadgeColors.color"
            :text="
              '€' +
              helpers.convertValueToCurrency(toursStore.tourDetails.profit)
            "
            class="body-text-3 text-center mb-0"
            data-testid="tour-summary-profit-field"
          />
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n-bridge';
import { useToursStore } from '@/store/pinia/tours.store.js';
import helpers from '@/helpers/helpers';
import languages from '../locales/languages';
import RegularBadge from '@/components/shared/customComponents/alertsBadges/regular-badge.vue';

const toursStore = useToursStore();
const { t } = useI18n({ locale: 'en', messages: languages });

// --- Computed ---
const regularBadgeColors = computed(() => {
  if (!toursStore.tourDetails.employeeId) {
    return {
      color: 'yellowLight',
      textColor: 'yellowDark',
    };
  }
  return {
    color:
      toursStore.tourDetails.profit > 0 ? 'lowOpacityGreen' : 'redErrorLight',
    textColor:
      toursStore.tourDetails.profit > 0 ? 'caribbeanDark' : 'redDanger',
  };
});
</script>

<style lang="scss" scoped></style>
