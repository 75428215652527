import { render, staticRenderFns } from "./profitability-settings.vue?vue&type=template&id=6822a005&scoped=true&data-testid=tours-profitability-settings-pannel&"
import script from "./profitability-settings.vue?vue&type=script&setup=true&lang=js&"
export * from "./profitability-settings.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./profitability-settings.vue?vue&type=style&index=0&id=6822a005&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6822a005",
  null
  
)

export default component.exports