<template data-testid="tours-dashboard">
  <v-container class="pa-0" v-if="!loadingStore.isGlobalLoading">
    <v-row>
      <v-col cols="12" class="py-0">
        <ProfitabilitySettings class="my-6" />
      </v-col>
    </v-row>
    <v-row v-if="toursStore.employeeTours.length > 0">
      <v-col
        cols="4"
        class="tours-scrollable-content py-0"
        id="tours-container"
      >
        <TourInformationCard
          :tour="item"
          class="pa-0 mb-6 clickable"
          :id="`tour-${item.shiftId}`"
          :data-testid="`tour-card-${i}`"
          v-for="(item, i) in toursStore.employeeTours"
          :key="i"
          @click="onTourCardClicked(item.shiftId)"
        />
      </v-col>

      <v-col
        cols="8"
        v-if="loadingStore.isPartialLoading"
        class="d-flex justify-center align-center"
      >
        <LoadingAnimation dense />
      </v-col>
      <v-col cols="8" class="py-0" v-else-if="toursStore.selectedTour">
        <v-card
          class="rounded-lg shadow-regular"
          data-testid="tour-details-card"
          v-if="toursStore.tourDetails"
        >
          <v-container class="pa-0">
            <v-row no-gutters>
              <v-col class="py-8 px-4">
                <v-card-title>
                  <span class="heading-h3" data-testid="tour-details-title">
                    {{ t('Title_.tourDetails') + ':' }}
                  </span>
                  <span
                    class="heading-h3 blueRegular--text pl-2"
                    data-testid="tour-details-tour-name-field"
                  >
                    {{ toursStore.tourDetails.shiftName }}
                  </span>
                </v-card-title>
                <v-card-text class="pt-2">
                  <v-container>
                    <TourInformationHeader />

                    <div
                      class="my-6"
                      v-if="toursStore.tourDetails.hourlyCostComponents"
                    >
                      <TourInformationHourlyCostCalculation />
                    </div>
                    <v-divider class="my-6"> </v-divider>
                    <v-row>
                      <v-col>
                        <p
                          class="heading-h4"
                          data-testid="tour-details-tour-summary-subtitle"
                        >
                          {{ t('Title_.subTitle.tourSummary') }}
                        </p>
                        <TourInformationSummary />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="mt-4">
                        <span
                          class="heading-h4 mb-0"
                          data-testid="tour-details-tasks-subtitle"
                          >{{ t('Title_.subTitle.tasks') }}
                        </span>
                        <span
                          class="heading-h4 mb-0 blueRegular--text"
                          data-testid="tour-details-tasks-quantity-field"
                        >
                          {{ toursStore.tourDetails.tasks.length || 0 }}
                        </span>
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <v-col
                        cols="4"
                        v-for="(task, i) in toursStore.tourDetails.tasks"
                        :key="i"
                      >
                        <TourTaskCard
                          :task="task"
                          :index="i"
                          :data-testid="`tour-details-tasks-card-${i}`"
                          class="tour-task-card"
                        />
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" class="pt-10">
                        <span
                          class="heading-h4"
                          data-testid="tour-details-missing-prescriptions-subtitle"
                          >{{
                            t('Title_.subTitle.missingAndExpiredPrescriptions')
                          }}
                        </span>
                        <span
                          class="heading-h4 blueRegular--text"
                          data-testid="tour-details-missing-prescriptions-quantity-field"
                        >
                          {{ expiredAndMissingPrescriptions.length || 0 }}
                        </span>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col
                        cols="4"
                        v-for="(
                          prescription, i
                        ) in expiredAndMissingPrescriptions"
                        :key="i"
                      >
                        <TourPrescriptionCard
                          :prescription="prescription"
                          :data-testid="`tour-information-missing-prescription-card-${i}`"
                        />
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" class="pt-10">
                        <span
                          class="heading-h4"
                          data-testid="tour-details-expiring-prescriptions-subtitle-field"
                          >{{ t('Title_.subTitle.expiringPrescriptions') }}
                        </span>
                        <span
                          class="heading-h4 blueRegular--text"
                          data-testid="tour-details-expiring-prescriptions-quantity-field"
                        >
                          {{ expiringPrescriptions.length || 0 }}
                        </span>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col
                        cols="4"
                        v-for="(prescription, i) in expiringPrescriptions"
                        :key="i"
                      >
                        <TourPrescriptionCard
                          :prescription="prescription"
                          :data-testid="`tour-details-expiring-prescription-card-${i}`"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="pt-10">
                        <span
                          class="heading-h4"
                          data-testid="tour-details-expiring-prescriptions-subtitle-field"
                        >
                          {{ t('Title_.subTitle.tourMap') }}
                        </span>
                        <div class="pt-2">
                          <TourMap
                            :tasks="mapTaskCoordinates"
                            :hub-coordinates="hubCoordinates"
                          />
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12">
        <EmptyListMessage data-testid="tours-dashboard-no-tour-empty-message" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n-bridge';
import { useLoadingStore } from '@/store/pinia/loading.store.js';
import { useToursStore } from '@/store/pinia/tours.store.js';
import EmptyListMessage from '@/components/shared/customComponents/emptyStates/empty-list-message.vue';
import languages from '../locales/languages';
import LoadingAnimation from '@/components/shared/customComponents/loadingAnimation/loading-animation.vue';
import ProfitabilitySettings from '../profitabilitySettings/profitability-settings.vue';
import TourInformationHeader from './tour-information-header.vue';
import TourInformationCard from './tourInformationCards/tour-information-card.vue';
import TourInformationHourlyCostCalculation from './tour-information-hourly-cost-calculation.vue';
import TourInformationSummary from './tour-information-summary.vue';
import TourMap from './tour-information-map.vue';
import TourPrescriptionCard from '../sharedCards/tour-prescription-card.vue';
import TourTaskCard from './tourInformationCards/tour-task-card.vue';
import { amplitudeComposable } from '@/helpers/composables/amplitude.js';

const { trackPage } = amplitudeComposable();
const loadingStore = useLoadingStore();
const toursStore = useToursStore();

const { t } = useI18n({ locale: 'en', messages: languages });

// --- Local Variables ---
const prescriptionTypes = {
  expiring: 2,
  expired: 3,
  missing: 4,
};

// --- Methods ---
const onTourCardClicked = async (shiftId) => {
  trackPage(toursStore.selectedHub, {
    resourceId: shiftId,
    routeName: 'tourInformation.shiftDetails',
  });
  await toursStore.getTourDetails(shiftId);
};

// --- Computed ---
const mapTaskCoordinates = computed(() => {
  return toursStore.tourDetails.tasks.map((task) => {
    return { lat: task.location?.lat, lon: task.location?.lon };
  });
});

const hubCoordinates = computed(() => {
  const hub = toursStore.hubList.find(
    (hub) => hub.id === toursStore.selectedHub
  );
  return {
    lat: hub.latitude,
    lon: hub.longitude,
  };
});

const prescriptions = computed(() => {
  return toursStore.tourDetails.prescriptions;
});

const expiredAndMissingPrescriptions = computed(() => {
  return prescriptions.value.filter(
    (prescription) =>
      prescription.status.Value === prescriptionTypes.expired ||
      prescription.status.Value === prescriptionTypes.missing
  );
});

const expiringPrescriptions = computed(() => {
  return prescriptions.value.filter(
    (prescription) => prescription.status.Value === prescriptionTypes.expiring
  );
});
</script>

<style lang="scss" scoped>
@import url('../../../../node_modules/ol/ol.css');

.tour-task-card {
  height: 100%;
}

.clickable {
  cursor: pointer;
}

.tours-scrollable-content {
  /* min height: 100% of page height minus (header title and selector container height (250px) + profitability settings height (250px) plus footer and padding height (75px)) */
  min-height: calc(100vh - 575px);

  /* max height: 100% of page height minus (header title and selector container height (250px) + profitability settings height (250px)) */
  max-height: calc(100vh - 500px);
  overflow: auto;
}
</style>
