<template data-testid="tour-map">
  <div ref="mapRoot" id="ol-map"></div>
</template>

<script setup>
import { boundingExtent } from 'ol/extent';
import { defineProps, ref, onMounted } from 'vue';
import { fromLonLat } from 'ol/proj';
import { Point } from 'ol/geom';
import { Text, Fill, Style, Icon } from 'ol/style';
import Feature from 'ol/Feature';
import hubPin from '@/assets/tours/hub_pin.png';
import Map from 'ol/Map.js';
import OSM from 'ol/source/OSM';
import taskPin from '@/assets/tours/task_pin.png';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import View from 'ol/View.js';

// --- Props ---
const props = defineProps({
  tasks: {
    type: Array,
    required: true,
  },

  hubCoordinates: {
    type: Object,
    required: true,
  },
});

// --- Local Variables ---
const map = ref();
const mapRoot = ref();

// --- Methods ---
const createMap = () => {
  // create the map - initially centers map in Germany coordinate - sets max zoom to 17x
  map.value = new Map({
    target: mapRoot.value,
    layers: [
      new TileLayer({
        source: new OSM(),
      }),
    ],
    view: new View({
      center: fromLonLat([props.hubCoordinates.lon, props.hubCoordinates.lat]),
      zoom: 0,
      maxZoom: 17,
    }),
  });

  // create the hub marker
  createHubMarker();

  // create the task markers
  createAllTasksMarkers();

  // resizes and fit map to display all markers properly
  fitMap();
};

const createHubMarker = () => {
  const hubMarkerLayer = createHubMarkerLayer();
  const hubMarkerStyle = createHubMarkerStyle();

  hubMarkerLayer.setStyle(hubMarkerStyle);
  map.value.addLayer(hubMarkerLayer);
};

const createHubMarkerLayer = () => {
  return new VectorLayer({
    source: new VectorSource({
      features: [
        new Feature({
          type: 'geoMarker',
          geometry: new Point(
            fromLonLat([props.hubCoordinates.lon, props.hubCoordinates.lat])
          ),
        }),
      ],
    }),
  });
};

const createHubMarkerStyle = () => {
  return new Style({
    image: new Icon({
      src: hubPin,
      anchor: [0.5, 0],
      anchorOrigin: 'bottom-right',
    }),
  });
};

const createAllTasksMarkers = () => {
  for (let taskIndex = 0; taskIndex < props.tasks.length; taskIndex++) {
    const task = props.tasks[taskIndex];
    if (task && task.lat && task.lon) {
      createTaskMarker(task, taskIndex);
    }
  }
};

const createTaskMarker = (task, taskIndex) => {
  const taskMarkerLayer = createTaskMarkerLayer(task);
  const taskMarkerStyle = createTaskMarkerStyle(taskIndex);

  taskMarkerLayer.setStyle(taskMarkerStyle);
  map.value.addLayer(taskMarkerLayer);
};

const createTaskMarkerLayer = (task) => {
  return new VectorLayer({
    source: new VectorSource({
      features: [
        new Feature({
          type: 'geoMarker',
          geometry: new Point(fromLonLat([task.lon, task.lat])),
        }),
      ],
    }),
  });
};

const createTaskMarkerStyle = (taskIndex) => {
  return new Style({
    image: new Icon({
      src: taskPin,
      anchor: [0.5, 0],
      anchorOrigin: 'bottom-right',
    }),

    text: new Text({
      font: 'bold 12px sans-serif',
      text: (taskIndex + 1).toString(),
      offsetY: -20,
      textBaseline: 'middle',
      fill: new Fill({
        color: 'white',
      }),
    }),
  });
};

const fitMap = () => {
  const layers = map.value.getAllLayers();
  const vectors = layers.filter((layer) => layer instanceof VectorLayer);

  // checks if there is VectorLayers (markers)
  if (vectors.length > 0) {
    // gets the extent of each VectorLayer (marker)
    const vectorExtents = vectors.map((vector) =>
      vector.getSource().getExtent()
    );

    // gets the boundaries of the markers
    const extent = boundingExtent(vectorExtents);

    // fits the map to the boundary
    map.value.getView().fit(extent, map.value.getSize());

    // adjust the zoom so it gets some space between the markers and the borders
    map.value.getView().setZoom(map.value.getView().getZoom() - 0.5);
  }
};

// --- Lifecycle hooks ---
onMounted(() => {
  createMap();
});
</script>

<style scoped>
#ol-map {
  height: 400px !important;
}
</style>
